/* You can add global styles to this file, and also import other style files */

$color-primary: rgb(62, 146, 78);
$color-secondary: rgb(78, 78, 80);
$color-warning: rgb(241, 172, 83);
$color-danger: rgb(211, 48, 51);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// // Incomplete pallete to override material theme
// $green-palette: mat.define-palette(mat.$green-palette, 50);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-atider-primary: mat.define-palette(mat.$green-palette);
$front-atider-accent: mat.define-palette(mat.$green-palette);

// The warn palette is optional (defaults to red).
$front-atider-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$front-atider-theme: mat.define-light-theme((
  color: (
    primary: $front-atider-primary,
    accent: $front-atider-accent,
    warn: $front-atider-warn,
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($front-atider-theme);

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto'
);

@include mat.all-component-typographies($custom-typography);

// OpenLayers styles
@import '~ol/ol.css';

html {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;

    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
        width: 11px;
    }
    &::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG) ;
        border-radius: 6px;
        border: 3px solid var(--scrollbarBG);
    }
}

body {
    font-family: 'Roboto', 'monospace';
    margin: 0;
}

/**
* Thanks to Chris Coyier for his article about styling scrollbars
* https://css-tricks.com/the-current-state-of-styling-scrollbars/
*/
.scroller {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
        width: 11px;
    }
    &::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG) ;
        border-radius: 6px;
        border: 3px solid var(--scrollbarBG);
    }
}

.form-group {
    display: flex;
    flex-flow: column nowrap;
    // width: 100%;
    margin: 1rem 1rem 1rem 1rem;

    & label {
        margin-bottom: 0.5rem;
    }

    & input {
        padding: 0.8rem;
        border-radius: 0.2rem;
        border: none;
    }

    // &__small {
    //     width: 50% !important;
    // }
}



.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

//     & > * {
//         margin: 1%;
//         min-width: 31%;
//     }
}

.form-col {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.form-title {
    color: rgb(162, 162, 162);
}

.display-none {
    display: none !important;
}

.btn {
    cursor: pointer;
//     border: none;
//     border-radius: 0.5rem;
//     padding: 1rem;
//     outline: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-height: 3rem;
    font-size: 0.8rem;

    .mat-icon {
        width: 0.8rem ;
        height: 0.8rem;
    }

//     &__disabled {
//         opacity: 0.2;
//         cursor: default;

//         &:hover {
//             background-color: inherit !important;
//         }
//     }

    &-icon, i {
        margin-left: 0.5rem;
    }

//     &-small {
//         padding: 0.5rem;
//     }

//     &-primary {
//         color: white;
//         background-color: $color-primary;
    
//         &:hover, &:focus {
//             background-color: rgba(62, 146, 78, 0.2);
//         }
    
//         &-inverse {
//             color: $color-primary;
//             background-color: white;

//             &:hover, &:focus {
//                 background-color: rgba(62, 146, 78, 0.2);
//             }

//             &-bordered {
//                 border: 1px solid $color-primary
//             }
//         }
//     }

//     &-secondary {
//         color: white;
//         background-color: $color-secondary;
    
//         &:hover, &:focus {
//             background-color: rgba(78, 78, 80, 0.2)
//         }
    
//         &-inverse {
//             color: $color-secondary;
//             background-color: white;

//             &:hover, &:focus {
//                 background-color: rgba(78, 78, 80, 0.2)
//             }

//             &-bordered {
//                 border: 1px solid $color-secondary
//             }
//         }
//     }

//     &-warning {
//         color: white;
//         background-color: $color-warning;
    
//         &:hover, &:focus {
//             background-color: rgba(241, 172, 83, 0.2);
//         }
    
//         &-inverse {
//             color: $color-warning;
//             background-color: white;

//             &:hover, &:focus {
//                 background-color: rgba(241, 172, 83, 0.2);
//             }

//             &-bordered {
//                 border: 1px solid rgba(241, 172, 83, 0.9);
//             }
//         }
//     }

//     &-danger {
//         color: white;
//         background-color: $color-danger;
    
//         &:hover, &:focus {
//             background-color: rgba(211, 48, 51, 0.2);
//         }
    
//         &-inverse {
//             color: $color-danger;
//             background-color: white;

//             &:hover, &:focus {
//                 background-color: rgba(211, 48, 51, 0.2);
//             }

//             &-bordered {
//                 border: 1px solid rgba(211, 48, 51, 0.9);
//             }
//         }
//     }
}

.text-danger {
    color: $color-danger;
}

.snack-bar {
    &__error {
        background-color: rgba(211, 48, 51, 0.9);
        color: white;

        .mat-mdc-snack-bar-action {
            color: white;
        }
    }

    &__warning {
        background-color: rgba(241, 172, 83, 0.9);
        color: white;
    }

    &__success {
        // background-color: rgba(211, 48, 51, 0.9);
        // color: white;
    }

}

.container-fluid {
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
}

.mat-mdc-form-field {
    &-flex {
        background-color: transparent !important;
    }
    .mat-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// @media(max-width: 768px) {
//     .form-row {
//         & > * {
//             min-width: 48%;
//         }
//     }
// }

@media(max-width: 480px) {
    .btn {
        padding: 0.5rem;
    }

    
    // .form-row {
    //     & > * {
    //         min-width: 100%;
    //     }
    // }
}